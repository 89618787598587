<template>
    <div class="cardRechargeReport">
        <div class="filter-box">
            <div class="filter-bnts ">
                <div class="bnt verticalCenter" :class="{selected:queryType==0}" @click="nowadays()">
                    <div class="title">
                        <span>今天 <br/>({{new Date().Format("yyyy-MM-dd")}})</span>
                    </div>
                </div>
                <div class="bnt verticalCenter" :class="{selected:queryType==1}" @click="queryType=1">
                    <div class="title">
                        <span>按日期范围</span>
                    </div>
                </div>
                <div class="inline-block">
                    <el-date-picker class="from-date"
                        v-model="businessHours"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                        @change="change"
                        :disabled='queryType==0'
                    > </el-date-picker>
                </div>
                <div class="bnt-search" @click="GetMemberRechargeInfo()" >查询</div>
            </div>
            <div class="right-bnts">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
        </div>
        <div class="content-box">
            <div class="table-box" ref="tableBox" v-table-el-height="'tableEl'">
                <el-table ref="tableEl" id="out-tableRecharge" border
                    :data="tablePage"   
                    style="width: 100%;" 
                    show-summary 
                    :summary-method="getSummaries" 
                    v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" 
                    :default-sort = "{prop: 'date', order: 'descending'}">
                    <el-table-column fixed="left" prop="Card_No" label="卡号" min-width="120"></el-table-column>
                    <el-table-column fixed="left" prop="Cstm_Name" label="客户姓名" min-width="106"></el-table-column> 
                    <el-table-column fixed="left" prop="Mobile" label="手机号" min-width="130"></el-table-column> 
                    <el-table-column sortable prop="Card_KindName" label="类型" min-width="80"></el-table-column>
                    <el-table-column label="充值金额">
                        <el-table-column prop="Recharge_FaceMoney" label="账面金额" min-width="70"></el-table-column>
                        <el-table-column prop="Recharge_GiftMoney" label="赠送金额" min-width="70"></el-table-column>
                        <el-table-column prop="Recharge_GiftPoint" label="赠送积分" min-width="70"></el-table-column>
                        <el-table-column prop="Recharge_RealPay" label="实收金额" min-width="70"></el-table-column>
                    </el-table-column>
                    <el-table-column prop="Card_MoneyAfterRecharge" label="充值后余额" min-width="70"></el-table-column>
                    <el-table-column prop="Card_CostMoney" label="工本费" min-width="78"></el-table-column>
                    <el-table-column prop="Payment_Name" label="付款方式" min-width="78"></el-table-column>
                    <el-table-column prop="Rpt_Date" label="营业日期" min-width="125"></el-table-column>
                    <el-table-column prop="Operator_Name" label="操作人" min-width="60"></el-table-column>
                    <el-table-column prop="Operate_Pos" label="操作站点" min-width="108"></el-table-column>
                    <el-table-column prop="Operate_Time" label="操作时间" min-width="150"></el-table-column>
                    <el-table-column prop="Invoice_Money" label="发票金额" min-width="94"></el-table-column>
                    <el-table-column prop="Invoice_No" label="发票号码" min-width="126"></el-table-column>
                    <el-table-column prop="Remark" label="备注" min-width="90"></el-table-column>
                </el-table>
            </div>
            <div class="block">
                <el-pagination class="fy"
                     layout="prev, pager, next"
                     @current-change="current_change"
                     :hide-on-single-page="istag"
                     :total="total"
                     :page-size="pagesize"
                     background
                    >
                </el-pagination>
            </div>
        </div>
     </div>
</template>

<script> 
import { getDiffDay } from '/src/common'
/**会员卡充值明细表 */
export default {
    name:'cardRechargeReport',
    data(){
        return {
            total:1,//默认数据总数
            pagesize:9,//每页的数据条数
            currentPage:1,//默认开始页面
            istag: true,
            /**表格数据 */
            tableList:[],
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            queryType:0, //0当天数据,1按日期范围
        }
    },
    computed:{
        /**分页数据 */
        tablePage(){
            return this.tableList?.slice((this.currentPage-1)*this.pagesize,this.currentPage*this.pagesize)
        }
    },
    mounted(){
        this.$nextTick(()=> {
            this.queryType=0
        })
    },
    methods:{
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                let height=this.$refs.tableBox.clientHeight - 120;
                this.pagesize=parseInt(height/50);
            }
        },
        /**分页 */
        tableRowClassName({rowIndex}){
            if (rowIndex === 0) {
            return 'th';
            }
            return '';
        },
        switchChange(){
          this.istag = !this.istag;
        },
        current_change:function(currentPage){
            this.currentPage = currentPage;
        },
        /**Excel导出 */
        exportExcel(){
            if(this.$refs.tableEl){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.tableEl,
                    titleName:"会员卡充值明细",
                    list:this.tableList
                })
            }
        },
        /**今日 */
        nowadays(){
            this.queryType=0
            this.businessHours=[new Date(),new Date()]
            this.GetMemberRechargeInfo()
        },
        change(){
            if(this.queryType==0){
                this.queryType=1
            }
        },
        /**获取会员充值明细 */
        GetMemberRechargeInfo(){
            let userInfo=this.$auth.getUserInfo();
            let time = JSON.parse(JSON.stringify(this.businessHours));
            if(getDiffDay(time[0],time[1])>90){
                this.$message.warning("最多只可查询90天的数据!");
                return;
            }
            let param={
                User_ID:userInfo?.User_ID,
                Rpt_Date:new Date().Format("yyyy-MM-dd"), //营业日期
                Range_BeginDate: new Date(time[0]).Format("yyyy-MM-dd"),//范围开始日期
                Range_EndDate:new Date(time[1]).Format("yyyy-MM-dd"),//范围结束日期
                Query_Type:this.queryType, //0当天数据,1按日期范围
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log('param:'+ JSON.stringify(param))
            this.$httpAES.post("Bestech.CloudPos.GetMemberRechargeInfo",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList = d.ResponseBody
                    this.tableList.forEach(item=>{
                        item.Rpt_Date = new Date(item.Rpt_Date).Format("yyyy-MM-dd")
                        item.Operate_Time = new Date(item.Operate_Time).Format("yyyy-MM-dd hh:mm:ss")
                    })
                    this.setTablePageSize()
                    this.total= this.tableList.length;
                    this.currentPage=1
                }else{
                     this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e);
                console.log(e);
            })
        },
        getSummaries(param) {
            const { columns, data } = param
            const sums = []
            columns.forEach((column, index) => {
                if (index === 0) { // 只找第一列放合计
                    sums[index] = '合计：'
                    return
                }else if (index===4||index===5||index===6||index ===7||index===8||index===9 ||index===15) {
                    const values = data.map(item => Number(item[column.property]))
                    if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return  Number(Number(prev)+Number(curr)).toFixed(2)
                        } else {
                            return  Number(prev).toFixed(2)
                        }
                    },0)} else {
                        sums[index] = 'N/A'
                        }
                    } else {
                        sums[index] = '--'
                        }
                    })
                return sums
        }
    },
}
</script>

<style lang="scss">
@import './cardRechargeReport.scss'
</style>